@tailwind components;
@tailwind utilities;

@import './node_modules/react-perfect-scrollbar/dist/css/styles';

@import 'variables';

html, body {
    background-color: #ecf0fa !important;
}

.mantine-Menu-dropdown, .mantine-Alert-root {
    box-shadow: -8px 12px 18px 0 #dadee8;
}

.mantine-Button-root {
    box-shadow: -2px 8px 10px 0 #dadee8;;
}